import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import moment from "moment"
import { navigate } from "gatsby"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  PrimaryButton,
  Dropdown,
  DateDropdown,
} from "../components"
import { getCurrentPath, getCountries } from "../redux/actions"
import ACTIONS from "../redux/actions/actionTypes"
import provinces from "../utils/provinces"
import { mq } from "../utils/media-query"
import { isInclusivelyAfterDay } from "../utils/time-related-func"
import { validTravelPlan } from "../utils/helpers"

const GetQuoteDetails = ({
  location,
  getCurrentPath,
  travelPlan,
  countries,
  apiHealth,
}) => {
  const dispatch = useDispatch()
  const [continueOK, setContinueOK] = useState(false)

  useEffect(() => {
    getCurrentPath(location.pathname)
  }, [getCurrentPath, location.pathname])

  useEffect(() => {
    if (apiHealth === "OK" && countries.length === 0) dispatch(getCountries())
  }, [dispatch])

  useEffect(() => {
    setContinueOK(validTravelPlan(travelPlan))
  }, [travelPlan])

  const onProceedToNextPage = () => {
    navigate("/get-quote-travellers")
  }

  const onGoBackToPrevPage = () => {
    navigate("/")
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - travel details" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title
          title="tell us a bit about your travel plan"
          fontColor="var(--clr-primary-300)"
        />
        <SectionCenter>
          <div
            css={css`
              width: 90%;
              display: grid;
              row-gap: 2rem;
              margin: 0 auto 4rem auto;

              ${mq[0]} {
                width: 60%;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
                column-gap: 3rem;
                justify-content: center;
              }
            `}
          >
            <Dropdown
              statePropToChange="travelFrom"
              label="travelling from"
              dataArr={countries}
              firstOptionVal="China"
              actionType={ACTIONS.SET_TRAVEL_PLAN}
            />
            <Dropdown
              statePropToChange="travelTo"
              label="travelling to"
              dataArr={provinces}
              firstOptionVal="Alberta"
              actionType={ACTIONS.SET_TRAVEL_PLAN}
            />
            <DateDropdown
              label="policy start date"
              secondLevelStatePropToChange="startDate"
              renderCalendarInfo={() => <CustomInfoPanel />}
              readOnly
              firstLevelStatePropToChange="travelPlan"
              actionType={ACTIONS.SET_TRAVEL_PLAN}
            />
            <DateDropdown
              label="policy end date"
              secondLevelStatePropToChange="endDate"
              renderCalendarInfo={() => <CustomInfoPanel />}
              firstLevelStatePropToChange="travelPlan"
              readOnly
              isOutsideRange={day =>
                !isInclusivelyAfterDay(day, moment(travelPlan.startDate)) ||
                isInclusivelyAfterDay(
                  day,
                  moment(travelPlan.startDate).add(1, "years")
                )
              }
              disabled={!travelPlan.startDate}
              actionType={ACTIONS.SET_TRAVEL_PLAN}
            />
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <PrimaryButton
              textSize="0.875rem"
              textTransform="capitalize"
              maxWidth="none"
              margin="0 4rem 0 0"
              onClick={onProceedToNextPage}
              disabled={!continueOK}
            >
              Continue
            </PrimaryButton>
            <SecondaryButton
              buttonColor="var(--clr-neutral-400)"
              textSize="0.875rem"
              margin="none"
              onClick={onGoBackToPrevPage}
            >
              Go back
            </SecondaryButton>
          </div>
        </SectionCenter>
      </section>
    </LayoutQuoting>
  )
}

const CustomInfoPanel = () => (
  <div
    css={css`
      padding: 10px 21px;
      color: var(--clr-neutral-400);
      font-size: 0.75rem;
    `}
  >
    <span role="img" aria-label="note">
      &#x2755;
    </span>
    The maximum length of policy is up to 1 year.
  </div>
)

const mapStateToProps = ({ travelPlan, countries, apiHealth }) => ({
  travelPlan,
  countries,
  apiHealth,
})

export default connect(mapStateToProps, { getCurrentPath })(GetQuoteDetails)
